




















import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import './scss/PopupConfirmation.scss';

@Component({
    name: 'PopupConfirmation',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class PopupConfirmation extends Vue {
    $refs!: { basePopup: any }

    @Prop({ default: false }) popupOpen!: boolean;
    @Prop({}) title!: string;
    @Prop({ default: 'Подтвердить'}) confirmText!: string;
    @Prop({ default: 'Закрыть'}) cancelText!: string;
    @Prop({ default: false}) confirmBtnDisabled!: boolean;
    @Prop({ default: true}) hideCancelAction!: boolean;
    @Prop({ default: false}) hideConfirmAction!: boolean;
    @Prop({ default: false}) defaultStylesForContent!: boolean;
    @Prop({ default: false }) isErrorPopup!: boolean;

    timeToShowErrorPopup: number = 2000;

    emitClose() {
        this.$emit('close');
    }

    emitConfirm() {
        this.$emit('confirm');
    }

    @Watch('popupOpen')
    watchPopupOpen() {
        if (this.popupOpen) {
            this.$refs.basePopup.openPopup();
            if (this.isErrorPopup) {
                setTimeout(() => this.$refs.basePopup.closePopup(), this.timeToShowErrorPopup);
            }
        }
        else this.$refs.basePopup.closePopup();
    }

    confirm() {
        this.emitConfirm();
        this.emitClose();
    }
}
